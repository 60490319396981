<template>
	<div :style="background" class="about">
		<my-title></my-title>
		<div class="body">
			<div class="item-id">
				<span>请输入密码：</span>
				<input class="id-input" type="password" v-model="setPassword" />
			</div>
		</div>
		<div class="bottom">
			<span class="sure" @click="sureDialAbout">确定</span>
		</div>
	</div>
</template>
<script>
import MyTitle from "../components/MyTitle.vue"
import api from "../api/index";
import { Toast } from 'vant'
import QRCode from 'qrcodejs2'
export default {
	components: {
		MyTitle
	},
	data () {
		return {
			background: {
				backgroundColor: '#5A9AD1',
				// 背景图片地址
				backgroundImage: 'url(' + require('../assets/image/lineup_comm_bg_land.png') + ')',
				// 背景图片是否重复
				backgroundRepeat: 'no-repeat',
				// 背景图片大小
				backgroundSize: '100% auto',
				// 背景图片位置
				backgroundPosition: '0 0'

			},
			macAddr: '',
			LEDAddr: '',
			resetScoket: true,
			socket: null,
			timer: null,
			setPassword: '',
		}
	},
	mounted () {
		this.macAddr = this.$route.params.id
		localStorage.setItem("macAddr", this.macAddr)
	},
	methods: {
		sureDialAbout () {
			if (!this.setPassword) {
				Toast('请输入密码')
				return
			}
			this.getValidatePassword()
		},
		//叫号器 - 设备密码校验
		getValidatePassword: async function () {
			const res = await api.validatePassword(this.macAddr, this.setPassword);
			if (res.code == 200) {
				this.dialogAbout = false
				localStorage.setItem("macAddr", this.macAddr)
				this.$router.replace({
					name: 'DeviceConnect',
				})
			} else {
				Toast(res.msg + '')
			}
		},
	}
}
</script>
<style scoped lang="scss">
.about {
	height: 100%;
	box-sizing: border-box;
	display: flex;
	position: relative;
	overflow: auto;
	flex-direction: column;
	.body {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 14px;
		padding-top: 160px;
		box-sizing: border-box;
		.item-id {
			font-size: 30px;
			padding-bottom: 40px;
			display: flex;
			flex-direction: row;
			.id-input {
				color: #000;
			}
			.id-modify {
				background-color: #3f76fe;
				margin-left: 10px;
				border-radius: 2px;
				padding: 2px 5px;
			}
		}
	}
	.bottom {
		display: flex;
		color: #fff;
		width: 100%;
		padding-top: 120px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		.sure {
			background-color: #84c7ff;
			border-radius: 5px;
			font-size: 25px;
			width: 230px;
			height: 60px;
			line-height: 60px;
			text-align: center;
		}
	}
}
.about-input {
	color: #000;
	margin-top: 10px;
}
</style>
